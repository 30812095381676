// 开发环境域名

// const host_development = 'https://fqwlkj.cn'
const host_development = 'https://saas.shopape.cn' //admin admin112233

export default {
    // 版本
    version: '1.6.5',
    baseURL: process.env.NODE_ENV == 'production' ? '' : host_development,
    tencentMapKey: 'FWEBZ-WHSHV-IRFPO-UNMRL-5EUWV-BFBFW'
}
