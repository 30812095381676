import Main from '@/layout/main.vue'
import Blank from '@/layout/blank.vue'
const routes = [
    {
        path: '/order',
        name: 'order',
        meta: { title: '订单' },
        redirect: '/order/lists',
        component: Main,
        children: [
            {
                path: '/order/lists',
                name: 'lists',
                meta: {
                    title: '订单管理',
                    parentPath: '/order',
                    permission: ['view']
                },
                component: () => import('@/views/order/lists.vue')
            },
            {
                path: '/order/import_order.vue',
                name: 'lists',
                meta: {
                    title: '导入订单',
                    parentPath: '/order',
                    permission: ['view']
                },
                component: () => import('@/views/order/import_order.vue')
            },
            {
                path: '/order/factory_order.vue',
                name: 'lists',
                meta: {
                    title: '工厂订单',
                    parentPath: '/order',
                    permission: ['view']
                },
                component: () => import('@/views/order/factory_order.vue')
            },
            {
                path: '/order/order_edit',
                name: 'order_edit',
                meta: {
                    hidden: true,
                    title: '订单详情',
                    parentPath: '/order',
                    prevPath: '/order/lists',
                    permission: ['view']
                },
                component: () => import('@/views/order/order_detail.vue')
            }
        ]
    }
]

export default routes
